/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  &, &:active {
    color: $link;
    cursor: pointer;
    text-decoration: none;
  }
  &:hover {
    color: $link;
    text-decoration: underline;
  }
  &:visited {
    color: $visited;
  }
}
