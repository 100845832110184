@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';

$header-color: $AU_blue_shade_5;
$pane_bg-color: $AU_gray_shade_3;
$label-color: $AU_blue_shade_4;

.container {
  display: flex;
  margin: 0 auto;

  @include mq($until: tablet){
    flex-direction: column;
  }
}

.occupancy_container {
  display: flex;
  flex-direction: row;
  @include mq($from: mobile){
    justify-content: space-between;
  }
}

.header {
  font-family: $global-font-sans;
  font-weight: 600;
  line-height: (16px / 13px);
  padding: 10px 0 8px;
  border-bottom: 1px solid $header-color;
}

.pane {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  background-color: $pane_bg-color;
}

.checklist {
  composes: pane;

  @include mq($from: tablet-ls){
    margin-left: 5px;
  }
}

.label {
  text-align: left;
  display: flex;
  color: $label-color;
  white-space: nowrap;
  @include mq($until: mobile-ls){
    margin-right: 10px; 
  }
  @include mq($from: tablet-ls){
    text-align: center;
  }
  @include mq(tablet, tablet-ls){
    width: 100px;
  }
}

.value {
  text-align: left;
  display: inline-block;
  text-transform: capitalize;
  justify-content: space-between;
  width: 50px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @include mq(tablet, tablet-ls){
    width: 100px;
  }
}

.occupancy_label {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}

.title {
  display: flex;
  justify-content: space-between;
}