.container {
  display: flex;
}

.icon {
  padding-right: 5px;
}

.overflow {
  padding-top: 2px;
}