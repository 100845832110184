@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

$border-color: $AU_blue_shade_4;

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.inner_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 10px;
  @include mq($from: desktop){
    padding-left: 20px;
    padding-right: 20px;
  }
}

.endpoint_container {
  display: flex;
  @include mq($from: desktop){
    margin-top: 10px;
  }
}

.title {
  display: flex;
  align-items: center;
  margin: 0 7px 0 0;
  @include font-size(13px, 22px);
}

.title::after{
  content: ' :';
}

.endpoint {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 1px;
  @include mq($until: mobile-ls){
    display: inline-block;
    max-width: 140px;
  }
  @include mq(mobile-ls, tablet){
    display: inline-block;
    max-width: 200px;
  }
  @include mq(tablet, tablet-ls){
    display: inline-block;
    max-width: 500px;
  }
}

.textarea {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.custom_input {
  flex: 1;
  textarea {
    flex: 1;
  }
}

.custom_button, .custom_button__copied {
  display: flex;
  align-items: center;
  text-align: right;
  min-height: 30px !important;
  height: 30px !important; 
}

.custom_button {
  padding: 0;
  @include mq(tablet, desktop){
    padding: 0;
  }
  @include mq($from: desktop){
    padding: 10px 0 0;
  }
}

.custom_button__copied {
  @include mq($from: desktop){
    padding-top: 10px;
  }
}