@import 'src/css/globals.scss';
@import 'src/css/tools/tools.no-user-select';
@import 'node_modules/sass-mq/mq';

$legend_bg-color: #FFFFFF;
$legend_header_border-color: $AU_blue_shade_4;
$tooltip_field-color: $AU_blue_shade_4;
$tooltip_value-color: $AU_blue_shade_3;

.legend {
  /* remove display: none !important once events are available */
  display: none !important;
  @include no-user-select();
  position: absolute;
  background-color: $legend_bg-color;
  font-size: 11px;
  line-height: 1;
  min-width: 250px;
  display: flex;
  flex-direction: column;


  @include mq($until: tablet){
    bottom: 10px;
    right: 16px;
    left: 16px;

  }
  @include mq($from: tablet){
    right: 24px;
    padding-bottom: 12px;
  }

  .item {
    @include mq($until: tablet){
      display: none;
    }
    @include mq($from: tablet){
      height: 20px;
      display: flex;
      align-items: center;
      line-height: 1;
      padding: 0 16px;
    }
  }

  /* checkbox */
  i  {
    font-size: 15px;
    margin-right: 12px;
    min-width: 15px;
  }

  .icon {
    height: 15px;
    width: 15px;
    margin: 0 10px 0 0;
  }

  .label {
  }

  .value {
    padding-left: 18px;
    margin-left: auto;
  }
}

.header {
  display: flex;
  align-items: center;
  padding: 0 16px;

  font-weight: 600;

  @include mq($until: tablet){
    font-size: 14px;
    line-height: 40px;
  }
  @include mq($from: tablet){
    border-bottom: 1px solid $legend_header_border-color;
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 32px;
  }
}

.score {
  margin-left: auto;
}

.info {
  display: flex;
  padding-top: 5px;
  > div > span {
    display: block;
    font-size: 13px;
    font-family: Arimo;
    margin-bottom: 4px;
  }
  > div:first-child {
    // Vertical headers
    text-align: right;
    margin-right: 5px;
    color: $tooltip_field-color;
  }
  > div:last-child {
    color: $tooltip_value-color;
  }
}
