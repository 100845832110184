@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';

@import '../variables.scss';

.container {
  /* */
  display: flex;
  justify-content: center;
}

.compact {
  line-height: 1;
  padding: 5px 15px;

  > .label, > .value {
    line-height: (16px / 13px);
  }

}

.label {
  color: $dashboard_stat_label-color;
  white-space: nowrap;
}

.value {
  color: $dashboard_stat_value-color;
  text-align: center;
}
