@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';

$bg-color: #FFFFFF;
$border-color: $AU_blue_shade_4;
$track_bg-color: $AU_blue_shade_4;
$thumb_bg-color: $secondary_btn;
$range-color: $AU_blue_shade_3;

.container {
  @include mq($until: desktop){
    display: none;
  }
  @include mq($from: tablet){
    z-index: 1;
    position: absolute;
    line-height: 1;
    background-color: $bg-color;
    border-radius: 150px;
  }
  @include mq($from: desktop){
    display: flex;
    bottom: 18px;
    height: 34px;
    padding: 3px 8px;
    border: solid 1px $border-color;
    align-items: center;
  }
}

.bottomLeft {
  left: 10px;
}

.bottomRight {
  right: 20px;
}

.icon {
  @include mq($until: desktop){
    display: none;
  }
  @include mq($from: desktop){
    cursor: pointer;
    display: inline-block;
    width: 14px;
    height: 14px;
  }
}

.screen {
  composes: icon;
  @include mq(tablet, desktop){
    display: inline-block;
    width: 36px;
    height: 36px;
    background: url('../../images/maximize_screen.svg') no-repeat 50% / 20px 20px;
  }
  @include mq($from: desktop){
    margin: 1px 8px 1px 3px;
    background: url('../../images/maximize_screen.svg') no-repeat 0 0 / 14px 14px;
  }
}

.maximized {
  composes: screen;
  @include mq(tablet, desktop){
    background: url('../../images/minimize_screen.svg') no-repeat 50% / 20px 20px;
  }
  @include mq($from: desktop){
    background: url('../../images/minimize_screen.svg') no-repeat 0 0 / 14px 14px;
  }
}

.zoom {
  display: none;
  @include mq($from: desktop){
    display: flex;
    align-items: center;
  }
}

.zoomIn {
  composes: icon;
  background: url(../../images/zoom_in.svg) no-repeat 0 0 / 14px 14px;
}

.zoomOut {
  composes: icon;
  background: url(../../images/zoom_out.svg) no-repeat 0 0 / 14px 14px;
}

.slider {
  &:global(.rc-slider) {
    height: 2px;
    width: 56px;
    padding: 0;
    margin: 7px 0;
    display: inline-block;
    /* add caps on the sides to avoid handle sliding beyond the gutter */
    border-left: 5px solid $track_bg-color;
    border-right: 5px solid $track_bg-color;
  }

  :global(.rc-slider-rail), :global(.rc-slider-track), :global(.rc-slider-step) {
    background-color: $track_bg-color;
    cursor: pointer;
    height: 2px;
  }

  :global(.rc-slider-handle) {
    border: none;
    background-color: $thumb_bg-color;
    width: 8px;
    height: 8px;
    margin-top: -3px;
    margin-left: -4px;
  }
}

.output {
  margin: 0 1px 0 5px;
  color: $range-color;
}
