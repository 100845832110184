@import 'src/css/globals.scss';

$container_bg-color: #FFFFFF;
$container_border-color: $AU_blue_shade_4;
$label-color: $AU_blue_shade_4;

.container {
  background: $container_bg-color;
  z-index: 20;
  border: 1px solid $container_border-color;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  padding: 30px 40px 40px;
  min-width: 480px;
  max-width: 600px;
}

.current {
  padding: 8px 0 24px;
}

.change {
  padding-bottom: 6px;
}

.label {
  color: $label-color;
}

.delimiter {
  color: $label-color;
  padding: 0 4px;
}

.value {
  color: inherit;
}

.buttons {
  display: flex;
  margin-top: 24px;
}

.apply {
  max-width: 172px;
}

.cancel {
  padding: 0;
  margin-left: 24px;
}
