@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/sass-mq/mq';

@import '../variables.scss';

.container_outer {
  display: flex;
  width: 223px;
  min-height: 400px;
  margin: 0 auto;
  background: url('../../images/vehicle_outline.svg') no-repeat 45%/102px;
  background-position-y: 100px;
  margin-bottom: 20px;
}

%common {
  position: relative;
  line-height: 1.5;
}

.front_left {
  @extend %common;
  right: 65px;
  top: 15px;
  @include mq($from: tablet-ls){
    right: 15px;
  }
  @include mq($from: desktop){
    top: 10px;
    right: 35px;
  }
}

.front_right {
  @extend %common;
  right: -70px;
  top: 15px;
  @include mq(tablet-ls, desktop){
    right: -15px;
  }
  @include mq($from: desktop){
    right: -35px;
    top: 10px;
  }
}

.rear_left {
  @extend %common;
  top: 290px;
  right: 295px;
  @include mq($from: tablet-ls){
    right: 240px;
    top: 320px;
  }
  @include mq($from: desktop){
    right: 265px;
  }
}

.rear_right {
  @extend %common;
  top: 320px;
  right: 160px;
  @include mq($until: tablet-ls){
    right: 160px;
    top: 290px;
  }
  @include mq(tablet-ls, desktop){
    right: 205px;
  }
  @include mq($from: desktop){
    right: 195px;
  }
}

.label {
  color: $asset_status_checklist-text-color;
  margin-right: 5px;
}

.label::after {
  content: " :"
}

.value {
  text-align: center;
  text-transform: capitalize;
}

.low, .normal, .alert {
  text-transform: capitalize
}

.low::before, .alert::before {
  @include au-icon("\27");
  padding-right: 5px;
}

.low {
  color: $bytestream-service-color;
}

.normal {
  color: $asset_status_checklist-text-color;
}

.alert {
  color: $behaviors-query-service-color;
}

.status, .psa, .door {
  display: flex;
  min-width: 115px;
  flex-direction: row;
}