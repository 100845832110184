@import 'src/css/globals.scss';
@import 'src/css/tools/tools.no-user-select';
@import 'node_modules/sass-mq/mq';

@import '../variables.scss';

$title-color: #FFFFFF;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 5;
  top: 0;
  width: 100%;
  height: 54px;
  background-color: $global_navigation_breadcrumbs-background-color;
}

.ontop {
  composes: container;
  z-index: 10;
}

.title {
  left: auto;
  right: 16px;
  color: $title-color;
  font-family: $global-font-sans;
  font-weight: 600;
  text-transform: uppercase;
  background-color: $global_navigation_breadcrumbs-background-color;
  z-index: 5;

  @include mq($from: tablet){
    right: 24px;
  }
}

.button {
  @include no-user-select();
  display: flex;
  align-items: center;
  position: absolute;
  left: 16px;
  height: 54px;
  font-family: $global-font-sans;
  font-weight: bold;
  color: $secondary_btn;
  text-transform: capitalize;

  @include mq($from: tablet){
    left: 24px;
  }

  > span {
    line-height: 1;
  }
}

.add {
  composes: button;
  justify-content: center;

  &:before {
    content: "";
    display: block;
    width: 25px;
    height: 26px;
    background: url('../../images/add.svg') no-repeat center center / 25px 25px;
  }
  @include mq($until: tablet){
    flex-direction: column;
  }
  span {
    @include mq($until: tablet){
      font-size: 10px;
      margin-top: 3px;
    }
    @include mq($from: tablet){
      font-size: 13px;
      margin-left: 10px;
    }
  }
}

.save {
  composes: button;
  left: auto;
  right: 16px;
  background-color: $global_navigation_breadcrumbs-background-color;
  z-index: 5;

  @include mq($from: tablet){
    right: 24px;
  }
}
