@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';

@import '../variables.scss';

.container {
    position: absolute;
    display:flex;
    justify-content: space-between;
    width: 100%;
    @include mq($until: tablet){
      flex-direction: column;
      height: 95%;
    }
}

.gps_header {
    margin: 0 5px 0 5px;
    display: flex;
    color: $dashboard_stat_label-color;
    @include mq($until: mobile-ls){
      margin: 0 25px 0 5px;
      width: 65px;
    }
}

.gps_header::after {
    content: ' : ';
}

.gps {
  @include mq($from: tablet) {
    width: 200px;
    line-height: 1.7;
    margin-left: 5px;
  }
}

.details_container, .gps_container {
    background: $dashboard_stat-background-color;
    padding: 5px;
    margin: 15px 15px 0 15px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.gps_container {
    margin-right: 15px;
    display: flex;
    align-items: center;
    height: 66px;
    padding-top: 5px;
    justify-content: center;
    @include mq($until: mobile-ls){
      margin: 0 15px 15px 15px;
      height: auto;
    }
    @include mq($from: tablet){
      width: 240px;
    }
}

.full {
    flex: 1;
    margin: 0 7px;
    padding: 7px;
    text-align: center;

    > .label {
      font-family: $global-font-sans;
      @include mq($until: desktop){
        line-height: (16px / 12px);
      }
      @include mq($from: desktop){
        line-height: (16px / 13px);
      }
    }

    > .value {
      line-height: (33px / 21px);
    }
  }

.label {
    color: $dashboard_stat_label-color;
    white-space: nowrap;
}

.value {
    color: $dashboard_stat_value-color;
}