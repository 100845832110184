@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';
@import 'src/css/tools/tools.no-user-select';
@import 'node_modules/sass-mq/mq';

$background-color: #ffffff;
$list_label-text-color: $AU_blue_shade_4;
$buttons-shadow-color: $link;
$button-background-color: $secondary_btn;
$button-text-color: #ffffff;

/* Minor gMaps tweaking for proper Close button positioning */
// :global(.infoBox) {
//    > img {
//     position: absolute!important;
//     z-index: 1;
//     right: 10px;
//     top: 25px;
//     width: 8px;
//     height: 8px;
//     @include mq(tablet, desktop) {
//       width: 20px;
//       height: 20px;
//       padding: 0 0 12px 12px;
//     }
//   }
// }

.container {
  font-family: $global-font-base;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  position: relative;
  background: $background-color;
  box-shadow: 0 0 2px rgba(0,0,0,0.3);
  // margin: 16px 0 0 0;
  cursor: default;

  // /* arrow shade */
  // &:before {
  //   content : " ";
  //   width: 0; height: 0;
  //   border-right: 11px solid transparent;
  //   border-left: 11px solid transparent;
  //   border-bottom: 11px solid rgba(0, 0, 0, 0.1);
  //   position: absolute;
  //   top: -11px;
  //   left: 136px;
  // }

  // /* arrow */
  // &:after {
  //   content : " ";
  //   width: 0; height: 0;
  //   border-left: 10px solid transparent;
  //   border-right: 10px solid transparent;
  //   border-bottom: 10px solid $background-color;
  //   position: absolute;
  //   top: -10px;
  //   left: 137px;
  // }
}

.content {
  flex: 1;
  display: flex;
  justify-content: center;
}

.list {
  display: table;
  line-height: 20px;
  margin: 20px 0 18px;
  .line {
    display: table-row;
  }
  .label {
    display: table-cell;
    text-align: right;
    color: $list_label-text-color;
  }
  .delimiter {
    padding: 0 6px;
  }
  .value {
    display: table-cell;
  }
}

.buttons {
  flex: 1 0 auto;
  display: flex;
  box-shadow: 0 2px 0 0 $buttons-shadow-color;
  border-radius: 0 0 3px 3px;
  background-color: $background-color;
}

.button {
  &, &:active, &:visited {
    @include no-user-select();
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    height: 36px;
    line-height: 36px;
    font-weight: 600;
    font-family: $global-font-sans;
    color: $button-text-color;
    padding: 0 16px;
    margin-right: 1px;
    text-align: center;
    justify-content: center;
    background-color: $button-background-color;
  }
  &:first-child {
    min-width: 120px;
  }
  &:last-child {
    margin-right: 0;
  }
  &:before {
    margin-right: 6px;
    font-size: 13px;
    width: 13px;
    height: 13px;
  }
  &:hover {
    cursor: pointer;
    color: $button-text-color;
    background-color: darken($button-background-color, 8%);
    text-decoration: none;
  }
}

.info {
  composes: button;
  &:before {
    @include au-icon("\e029");
  }
}

.note {
  composes: button;
  &:before {
    @include au-icon("\7b");
  }
}