@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/sass-mq/mq';

$alert-color: $alert;
$warning-color: #E49816;
$secondary_nav_desktop_bg-color: $AU_gray_shade_3;

.container {
  flex: 1;
  display: flex;
  flex-direction: column;

  @include mq($until: tablet){
    padding: 16px;
  }
  @include mq(tablet, desktop){
    padding: 15px 24px 10px;
  }
}

.header {
  margin-bottom: 10px;
}

.header::after {
  content: ' :';
}

.header_container {
  display: flex;
  background-color: $secondary_nav_desktop_bg-color;
  padding-top: 20px;
  @include mq($until: tablet){
    display: block;
  }
  @include mq($from: desktop){
    margin-top: 10px;
  }
}

.header_inner {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 50%;
  margin-left: 35px;
  @include mq($from: tablet-ls){
    margin-left: 25px;
  }
}

.navigation {
  /* */
}

.sub_header {
  margin-left: 10px;
  margin-bottom: 8px;
}

.warning {
  &:after {
    position: relative;
    @include au-icon("\37");
    color: $warning-color;
    position: relative;
    top: -6px;
  }
}