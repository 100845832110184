@import 'src/css/globals.scss';
@import '../variables.scss';
@import 'node_modules/sass-mq/mq';

.container {
  display: table;
  line-height: 1.5;
  @include mq($until: mobile-ls){
    margin: 0 auto;
  }
}

.row {
  display: table-row;
  padding: 2px 0;
  line-height: 30px;
}

.label {
  display: table-cell;
  text-align: right;
  max-width: 50%;
  color: $asset-report__metric-label-color;
  white-space: nowrap;
}

.value {
  display: table-cell;
  text-align: left;
  padding-left: 5px;
  color: $asset-report__metric-value-color;
  min-width: 75px;
}
