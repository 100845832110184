@import "src/css/globals.scss";

$gmaps_bg-color: #e5e3df;

.loader {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $gmaps_bg-color;
}
