@import "src/css/globals.scss";
@import 'node_modules/sass-mq/mq';

$bg-color: $AU_gray_shade_3;
$label-color: $AU_blue_shade_4;
$border-color: $AU_gray_shade_1;

.container {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 6px 24px;
  background-color: $bg-color;
}

.list {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
}

.label {
  color: $label-color;
  &:after {
    content: ' :'
  }
}

.label, .value {
  @include mq($until: tablet-ls) {
    display: none;
  }
}

.value {
  color: inherit;
  margin-left: 3px;
  @include mq(tablet, tablet-ls) {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.button {
  display: flex;
  align-items: center;
  margin-left: 24px;
  background-color: $bg-color;
  padding: 0 24px;
  height: 40px;
  font-size: 16px;
  @include mq($until: tablet-ls) {
    margin-left: 0;
    font-weight: 600;
    border: 2px solid;
  }
  @include mq($from: tablet-ls) {
    width: 70px;
    overflow: hidden;
    padding: 0 7px;
  }
}
