@import 'node_modules/@au/core/src/css/settings/settings.colors';

@import 'node_modules/@au/core/src/css/variables';

$page_title-color: #ffffff !default;

$global_navigation-background-color: $AU_blue_shade_1 !default;
$global_navigation_mobile-background-color: $AU_blue_shade_0 !default;
$global_navigation-separator-color: $AU_blue_shade_4 !default;
$global_navigation-border-color: $AU_blue_shade_3 !default;
$global_navigation-text-color: $AU_blue_shade_5 !default;
$global_navigation_moblie-text-color: #ffffff !default;
$global_navigation-text-color_active: #ffffff !default;
$global_navigation_breadcrumbs-background-color: $AU_blue_shade_1 !default;
$global_navigation_sub_menu-text-color: #ffffff !default;
$global_navigation_secondary_menu-text-color: $AU_blue_shade_3;
$global_navigation_secondary_menu-text-color_active: $AU_blue_shade_3;
$global_navigation_secondary_menu-background-color: $AU_blue_shade_3;
$global_navigation_secondary_menu_active-background-color: $secondary_btn;
$global_navigation_secondary_menu_arrow-text-color: $secondary_btn;
$global_navigation_secondary_menu_active_arrow-text-color: $AU_blue_shade_3;

$secondary_navigation-border-color_active: $AU_blue_shade_3;
$secondary_navigation-text-color: $AU_blue_shade_5;
$secondary_navigation-text-color_active: $AU_blue_shade_3;
$secondary_navigation-text-color_active__responsive: #ffffff;
$secondary_navigation-background-color_active: #ffffff;
$secondary_navigation-background-color_active__responsive: $AU_blue_shade_3;
$secondary_navigation-border-color: $AU_blue_shade_3;
$secondary_navigation-border-color__responsive: $AU_blue_shade_3;
$secondary_navigation_legend-text-color: $AU_gray_shade_0;
$secondary_navigation-text-color__responsive: $AU_blue_shade_3;

$dashboard_sidebar-background-color: #ffffff;

$dashboard_stat-background-color: $AU_gray_shade_3;
$dashboard_stat_label-color: $AU_blue_shade_4;
$dashboard_stat_value-color: $AU_blue_shade_3 !default;
$dashboard_stat_value_minified-color: $AU_blue_shade_5;

$au_stat_grid-background-color_plain: $AU_gray_shade_3;

$au_range_track-background-color: $AU_blue_shade_4;
$au_range_thumb-background-color: $secondary_btn;

$asset_alerts_summary__header-bordercolor: $AU_blue_shade_5;
$asset_alerts_summary__title-color: $AU_blue_shade_3;
$asset_alerts_summary__odd-background-color: $AU_gray_shade_3;
$asset_alerts_summary__view_more-color: $link;

$mini_line_chart-background-color: $AU_gray_shade_3;
$mini_line_chart-line-color: $AU_blue_shade_3;
$mini_line_chart_title-color: $AU_blue_shade_4;
$mini_line_chart_final_value-color: $AU_blue_shade_3;
$mini_line_chart_final_date-color: $AU_gray_shade_0;

$advanced_filter_creator_backgound-color: #ffffff;
$advanced_filter_creator_label-color: $AU_blue_shade_4;
$advanced_filter_creator_filters_background-color: $AU_gray_shade_3;
$advanced_filter_creator_section_header-color: $AU_blue_shade_3;
$advanced_filter_creator_hr-color: #d4d4d4;

$selection_summary_section_header-color: $AU_blue_shade_5;

$vehicle-report-section-separator-color: $AU_blue_shade_5;
$vehicle-report-attribute-label-color: $AU_blue_shade_4;

$table_header-background-color: $AU_blue_shade_6 !default;
$table_header-font-color: $AU_blue_shade_3 !default;
$table_search-font-color: $AU_blue_shade_3;
$table_search-border-color: $AU_gray_shade_1;
$table_scroll_bar-border-color: $AU_gray_shade_1;
$table_scroll_bar-background-color: $AU_gray_shade_2;
$table_scroll_bar_face-background-color: $AU_blue_shade_3;
$table_cell-color: $AU_blue_shade_3 !default;
$table_row_highlight-background-color: $AU_gray_shade_3 !default;
$table_row_odd-background-color: $AU_gray_shade_3;
$table_row_hovered-border-color: $AU_blue_shade_4;
$table_row_hovered-background-color: $hover;
$table_row_expanded-background-color: $hover;

$searchbox_container-background-color: $AU_blue_shade_3;
$searchbox_input-color: white;
$searchbox_input-fill-color: white;
$searchbox_button_hover-background-color: $secondary_btn;
$searchbox_results_container-background-color: white;
$searchbox_results_container-border-color: $AU_blue_shade_4;
$searchbox_result_label-color: $AU_blue_shade_5;
$searchbox_result_item-color: $AU_blue_shade_5;
$searchbox_result_item_hover-background-color: $hover;
$searchbox_result_item_hover-border-color: $AU_blue_shade_4;
$searchbox_result_item_highlight-background-color: $AU_blue_shade_7;
$searchbox_result_summary-color: $AU_gray_shade_0;

$global_filter_bar_background-color: $AU_blue_shade_4 !default;
$global_filter_bar_icons-color: #ffffff;
$global_filter_bar_muzzled_background-color: $alert;
$global_filter_bar_muzzled_text-color: #ffffff;
$global_filter_bar_too_many_pills_counter_color: $alert;

$asset-report__metric-label-color: $AU_blue_shade_4;
$asset-report__metric-value-color: $AU_blue_shade_3;

$geofence_link_separator-color: $AU_gray_shade_2;

$geofence_mobile_link-color: $link;
$geofence_mobile_link_container-background-color: $AU_gray_shade_3;
$geofence_mobile_link_caption-text-color: $AU_blue_shade_5;

$geofence_tab_selector-background-color: #ffffff;


$au_multiselect_drop_down_border-color: $AU_gray_shade_1;
$au_multiselect_drop_down_placeholder-color: $AU_gray_shade_3;
$au_multiselect_drop_down_hover-color: $AU_blue_shade_7;
$au_multiselect_drop_down-color: $AU_blue_shade_3;

$au_bar_chart_bar-color: $AU_blue_shade_4;

$user_profile_popout_title-color: $AU_blue_shade_3;
$user_profile_popout_title-border-color: $AU_blue_shade_4;
$user_profile_popout_section_header-color: $AU_blue_shade_3;
$user_profile_popout_section_header-background-color: $AU_blue_shade_6;
$user_profile_popout_section_label-color: $AU_blue_shade_4;
$user_profile_popout_section_hint-color: $AU_gray_shade_0;

$geofence_toolbar_border: $link;
$geofence_toolbar_color: $link;
$geofence_toolbar_background-color: #fff;
$geofence_toolbar_selected_color: #fff;
$geofence_toolbar_selected_background-color: $AU_blue_shade_3;
$geofence_toolbar_disabled_color: #fff;
$geofence_toolbar_disabled_background-color: $AU_gray_shade_0;

$geofence_edit-placeholder_color: #c4c4c4;

$asset_trips_checkbox_instruction_font-color: $AU_gray_shade_0;
$asset_trips_checkbox_instruction_font-mobile-color: $AU_blue_shade_4;

$asset_trips_table_row_background-color: $AU_gray_shade_3;
$asset_trips_table_timeline_range_background-color: $AU_blue_shade_7;
$asset_trips_table_timeline_handle-color: #4FAAD7; // secondary_btn?
$asset_trips_table_timeline_handle_url: url('../../images/handler_au.svg'); // TODO: make this a webfont and use the above color
$asset_trips_table_timeline_tick-color: $AU_blue_shade_4;
$asset_trips_table_timeline_marker-color-mobile: #1C96CF;
$asset_trips_table_expansion_header_font-color: $AU_blue_shade_4;
$asset_trips_table_accel_dot-color: $alert;
$asset_trips_table_brake_dot-color: $secondary_btn;
$asset_trips_table_idle_dot-color: $AU_blue_shade_0;
$asset_trips_table_disabled_checkbox-color: $AU_gray_shade_2;
$asset_trips_table_disabled_checkbox_border-color: $AU_gray_shade_1;
$asset_trips_table_slider_tooltip_background-color: $AU_blue_shade_3;
$asset_trips_table_slider_tooltip_font-color: #FFFFFF;

$asset_geofences_section_border: $AU_blue_shade_5;

$asset_status-background-color: $AU_gray_shade_3;
$asset_status_title-border-color: $AU_blue_shade_5;
$asset_status_checklist-text-color: $AU_blue_shade_4;

$fieldset_label_text-color: $AU_blue_shade_4;
$fieldset_value_text-color: $AU_blue_shade_3;
