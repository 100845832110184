@import 'node_modules/sass-mq/mq';
@import 'src/css/globals.scss';

.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    @include mq($until: desktop){
      margin-top: 15px;
    }
    @include mq($from: desktop){
      margin-top: 12px;
    }
  }

.common {
    flex: 1;
    display: flex;

    > div {
      flex: 1;
    }
  }

.bottom {
    display: flex;

    @include mq($until: tablet-ls){
      flex: 1 0 auto;
      flex-direction: column;
    }
    @include mq($from: tablet-ls){
      flex: 8 0 auto;
      margin-top: 3px;
    }

    > div {
      flex: 1;
    }
  }


.map {
    composes: common;
}

.scenario_runner {
  position: absolute;
  display:flex;
  // justify-content: space-between;
  align-items: center;
  width: 100%;
  @include mq($until: tablet){
    flex-direction: column;
    height: 95%;
  }
  width: 400px;
  height: 68px;
  left: 350px;
  background: #F5F5F5;
  padding: 15px;
  margin: 15px 15px 0 15px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
