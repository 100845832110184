@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';

$header-color: $AU_blue_shade_5;
$pane_bg-color: $AU_gray_shade_3;
$label-color: $AU_blue_shade_4;

.container {
  display: flex;
  margin: 0 auto;

  @include mq($until: tablet){
    flex-direction: column;
  }
}

.occupancy_container {
  margin: 10px 0 10px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid $header-color;
}

.header {
  font-family: $global-font-sans;
  font-weight: 600;
  line-height: (16px / 13px);
  padding: 10px 0 8px;
  border-bottom: 1px solid $header-color;
}

.pane {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  background-color: $pane_bg-color;
}

.current {
  composes: pane;
  line-height: 1.5;

  @include mq($from: tablet-ls){
    flex: 1;
    margin-right: 5px;
    margin-left: 5px;
  }
}

.checklist {
  composes: pane;
  min-width: 237px;

  @include mq(tablet, tablet-ls){
    margin-left: 13px;
  }
  @include mq($from: tablet-ls){
    margin-left: 5px;
  }
}

.section {
  flex: 1;
}

.status_icon {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  justify-content: space-evenly;
}

.status_title {
  display: flex;
  align-items: center;
}

.location {
  display: table;
  padding: 6px 0;
}

.row {
  display: table-row;
}

.label {
  text-align: right;
  display: table-cell;
  color: $label-color;
  white-space: nowrap;
}

.value {
  padding-left: 5px;
  display: table-cell;
}

.indicator_container {
  display: flex;
  align-items: center;
}

.indicator_title{
  margin-left: 10px;
  @include mq($from: tablet-ls){
    max-width: 265px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}