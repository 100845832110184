@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';

$pane_bg-color: $AU_gray_shade_3;
$pane_header-color: $AU_blue_shade_3;
$pane_headr-border-color: $AU_blue_shade_5;

.container {
  display: flex;
  background-color: $pane_bg-color;

  @include mq($until: tablet){
    flex-direction: column;
  }
}

.pane {
  flex: 1 0;
  padding: 16px 24px;

  @include mq($from: tablet){
    background-color: $pane_bg-color;
  }
}

.metadata {
  composes: pane;

  @include mq(tablet, tablet-ls){
    margin-right: 13px;
  }
  @include mq($from: tablet-ls){
    margin-right: 5px;
  }
}

.stats {
  composes: pane;

  @include mq($until: tablet){
    margin-top: 25px;
  }
}

.header {
  font-family: $global-font-sans;
  font-weight: 600;
  line-height: (16px / 13px);
  padding: 10px 0 8px;
  margin-bottom: 10px;
  color: $pane_header-color;
  border-bottom: 1px solid $pane_headr-border-color;
}
