@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';

.container {
  flex: 1;
  display: flex;
  flex-direction: column;

  @include mq($from: desktop){
    padding: 24px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.title {
  display: none;

  @include mq($from: desktop){
    display: flex;
    margin: 0;
    align-items: center;
    line-height: 26px;
  }
}
