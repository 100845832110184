@import '../variables.scss';

.container {
  flex: 1;
  display: flex;
}

.map {
  flex: 1;

  /* google maps global tweaking */
  :global .gm-style {
    > .gmnoprint {
      height: 7px!important;
      line-height: 7px!important;
      div {
        padding: 0!important;
        height: 7px!important;
        line-height: 7px!important;
        vertical-align: top!important;
      }
      div, span, a {
        font-size: .81em!important;
        color: $primary-background-color!important;
      }
    }
    > div {
      &:nth-child(n+2) {
        margin: 0;
        padding: 0;
        > a >  div, img {
          // NOTE: maybe unused
          width: 33px;
          height: 13px;
        }
      }
      &:nth-child(6) {
        div, span, a { font-size: .729em!important; }
      }
      /* hide Report a Problem link */
      &:nth-child(n+7) { display: none; }
    }
  }
}
