@import "../variables.scss";

.container {
  width: 1100px;
  z-index: 110;
  display: flex;
  flex-direction: column;
  overflow: visible;
  position: absolute;
  top: 0; bottom: 0; right: 0;
  box-shadow: -4px 0 5px 0px rgba(0,0,0,0.6);
  background-color: $primary-background-color;
}

.stickout {
  overflow: hidden;
  position: absolute;
  top: 4px; left: -35px;
  width: 35px; height: 70px;
  padding: 10px 0 10px 10px;
}

.circle {
  width: 50px; height: 50px;
  background-color: $primary-background-color;
  box-shadow: rgba(0,0,0,0.8) -1px 0 10px;
  border-radius: 50px;
}

.button {
  position: absolute;
  top: 26.5px; left: -12.5px;
  width: 25px; height: 25px;
  background: url('../../images/x_blue_ford.svg') no-repeat 50% / 25px 25px;
  transition: all 50ms ease;
  cursor: pointer;

  &:hover {
    width: 27px; height: 27px;
    top: 25px; left: -13.5px;
    background: url('../../images/x_blue_ford.svg') no-repeat 50% / 27px 27px;
  }
}

.content {
  flex: 1;
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: $primary-background-color;
  padding: 16px 24px;
}
