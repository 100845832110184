@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  @include mq($from: tablet-ls){
    margin-top: 10px;
  }
}

.top {
  flex: 0 0 auto;
  display: flex;
  background-color: white;
  flex-grow: 1;
  @include mq($until: tablet-ls){
    flex-direction: column;
  }
}

.common {
  flex: 1;
  display: flex;

  > div {
    flex: 1;
  }
}

.info {
  composes: common;
  margin-right: 5px;
  @include mq($until: tablet){
    flex-direction: column;
  }
  @include mq($until: tablet-ls){
    margin-top: 15px;
  }
}

.status {
  composes: common;
  @include mq($until: tablet-ls){
    margin-top: 15px;
  }
}