@import 'src/css/globals.scss';
@import 'src/css/tools/tools.no-user-select';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/sass-mq/mq';

@import '../variables.scss';

$gmaps_bg-color: #e5e3df;

.container {
  flex: 1;
  display: flex;
  height: 100%;
  position: relative;

  @include mq($until: tablet){
    overflow-x: hidden;
  }
  @include mq(tablet, tablet-ls){
    flex-direction: column;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  background-color: $dashboard_sidebar-background-color;

  @include mq($until: tablet){
    min-width: 100%;
    transition: $global-transition;
  }
  @include mq($until: tablet-ls){
    width: 100%;
  }
  @include mq($from: tablet-ls){
    width: 338px;
  }
}

.unfocused {
  composes: sidebar;
  margin-left: -100%;
}

.map {
  flex: 1;
  display: flex;

  @include mq($until: tablet){
    min-width: 100%;
  }
}

.panels {
  position: absolute;
  background: none;
  margin: 24px 24px 10px;
  width: 290px;
  z-index: 1;
}

.shadowbox {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
}

.stats {
  composes: shadowbox;
  padding: 7px 13px;
  background-color: $au_stat_grid-background-color_plain;
}

.group {
  composes: shadowbox;
}

.group, .group_portrait {
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 1;
}

.minimap {
  composes: shadowbox;
  display: flex;
  height: 195px;
  background-color: $gmaps_bg-color;
}
