@import 'src/css/globals.scss';

.container {
  align-items: center;
}

.dropdown {

}

.button {
  margin-left: 5px;
}
